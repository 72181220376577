*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	font-family: inherit;
}

*:focus {
	outline: none;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

html {
	font-size: 62.5%;
}

body {
	box-sizing: border-box;
	line-height: 1.4;
	font-family: 'Montserrat', sans-serif;
	scrollbar-width: thin;
	-webkit-tap-highlight-color: transparent;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}

::-webkit-scrollbar {
	width: 7px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #d3d3d3;
}

::-webkit-scrollbar-thumb:hover {
	background: darken(#d3d3d3, 10);
}

.btn {
	background: #6B238E;
	color: #fff;
	border: none;
	padding: 0.5rem 1rem;
	font-size: 1.4rem;
	font-weight: 500;
	border-radius: 0.5rem;
	cursor: pointer;
	margin: 1.5rem 0;

	&:active {
		background: lighten($color: #6B238E, $amount: 10);
	}

	&:disabled {
		opacity: 0.5;
		cursor: auto;
	}
}

.sketch-picker {
	max-width: 100% !important;
}

.modal__close {
	display: flex;
	justify-content: flex-end;
}

.ModalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
	z-index: 4;
}

.ReactModal__Content {
	border: none !important;
	border-radius: 0 !important;
}

.remove {
	position: relative;
	height: 1.4rem;

	&__tooltip {
		position: absolute;
		display: none;
		align-items: center;
		left: -1.45rem;
		bottom: -3.5rem;
		background: #000;
		opacity: 0.9;
		padding: 0.7rem;
		color: #fff;
		border-radius: 0.5rem;
		z-index: 1;

		&--show {
			display: flex;
		}

		&::before {
			content: '';
			border: 0.6rem solid transparent;
			border-bottom-color: black;
			position: absolute;
			top: -1.2rem;
			left: 1.65rem;
		}
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 2.7rem;
	height: 1.2rem;
	margin-right: 0.75rem;

	input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked+span {
			background-color: #00d23f;
		}

		&:focus+span {
			box-shadow: 0 0 1px #ae5ad6;
		}

		&:checked+span:before {
			transform: translateX(1.5rem);
		}
	}

	span {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #b5b5b5;
		transition: 0.4s;
		border-radius: 1.2rem;

		&:before {
			position: absolute;
			content: "";
			height: 0.8rem;
			width: 0.8rem;
			left: 0.2rem;
			bottom: 0.27rem;
			background-color: white;
			transition: .4s;
			border-radius: 50%;
			box-shadow: 0 2px 1px 0 rgba(22, 45, 61, .48), 0 0 3px 0 rgba(22, 45, 61, .12);
		}
	}
}

.react-tabs__tab--selected {
	font-weight: 700 !important;
}

@media screen and (max-width: 1199px) {
	.ReactModal__Content {
		top: 2rem !important;
		left: 2rem !important;
		right: 2rem !important;
		bottom: 2rem !important;
	}

	.ColorSchemeModal {
		margin-top: 2rem !important;
	}

	.ql-snow.ql-toolbar button,
	.ql-snow .ql-toolbar button {
		padding: 3px !important;
		width: 24px !important;
	}

	.swiper {
		&-slide {
			font-size: 1.4rem;
			line-height: 1.4rem;
			text-align: center;
			padding: 0.5rem 0;

			&-active {
				font-weight: 600;
			}
		}
	}

	.switch {
		margin-bottom: 0;
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}
}

@media screen and (min-width: 1200px) {
	.swiper-container {
		display: none;
	}
}